import React, { Suspense } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import styled from '@emotion/styled';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import About from '../components/About';
import Benefits from '../components/Benefits';
import FlexCard from '../components/FlexCard';
import OneRoof from '../components/OneRoof';
import { Link } from 'react-router-dom';
import { flexCardData } from '../utils/homeData';

const SliderComponent = React.lazy(() =>
  import('../components/SliderComponenet')
);

const VideoComponent = React.lazy(() => import('../components/VideoComponent'));

const MyButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: theme.palette.accent.main,
  fontWeight: '900',
  border: '2px solid white',
  color: 'white',
  // ".hey": {
  //   border: "24px solid yellow",
  // },
  padding: '10px 30px',

  transition: 'all 0.3s ease-in',
  [theme.breakpoints.down(724)]: {
    fontSize: 16,
    padding: '10px 20px',
  },
  '&:hover': {
    // opacity: 0.5,
    boxShadow: '0',
    backgroundColor: '#e83955',
    color: 'white',
    border: '2px solid transparent',
  },
}));
export const Box = styled.div`
  // width: 300px;
  border: 0.1px solid transparent;
  // border: 2px solid red;
  background-color: rgba(0, 0, 0, 0.5);

  // height: fit-content;
  position: relative;

  .office-video {
    position: absolute;
    object-fit: cover;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .box-content {
    margin: auto 0;
    padding: 30px 0;
    // border: 2px solid red;
  }
  // @media (min-width: 724px) {
  padding: 10px 0;
  // }
  h1 {
    // border: 2px solid red;
    margin-top: 6rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 29px;
    color: white;
    @media (min-width: 724px) {
      font-size: 4.3rem;
    }
  }
  .btn-container {
    // border: 2px solid green;
    display: flex;
    margin-bottom: 6rem;
    margin-bottom: 100px;
  }
  .btn {
    // display: block;
    // border: 2px solid red;

    margin-left: auto;
    margin-right: auto;
  }
`;

function Home() {
  return (
    <>
      <Box>
        <Suspense fallback={<></>}>
          <VideoComponent />
        </Suspense>

        <section className='box-content'>
          <h1>Collaborate, Create and Connect</h1>
          <div className='btn-container'>
            <div className='btn'>
              <Link to='/space-solutions'>
                <MyButton variant='contained' endIcon={<DoubleArrowIcon />}>
                  Book a space now
                </MyButton>
              </Link>

              {/* Testing MUI classes */}
              {/* <MyButton variant="contained" endIcon={<DoubleArrowIcon />}>
                Get Started
                <div className="hey">ddomdod</div>
              </MyButton> */}
              {/* Testing MUI classes */}
            </div>
          </div>
        </section>
      </Box>

      <Container
        sx={{
          maxWidth: '1400px',
        }}
        maxWidth={false}
      >
        <section
          className='flexcard-container'
          style={{
            margin: '100px 0px',
          }}
        >
          <AnimationOnScroll animateIn='animate__fadeInUp'>
            <FlexCard reverse={'reverse'} {...flexCardData[1]} />
          </AnimationOnScroll>

          <AnimationOnScroll animateIn='animate__fadeInUp'>
            <FlexCard {...flexCardData[2]} />
          </AnimationOnScroll>
        </section>

        <OneRoof />

        <Benefits />
        <About />
      </Container>
    </>
  );
}

export default Home;
