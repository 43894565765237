import React, { useState } from 'react';
import { aboutData } from '../utils/aboutData';
import './About.scss';
function About() {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className='about'>
      <section className='about-wrapper'>
        <h2>WHAT OUR CLIENT SAY ABOUT US</h2>
        <div className='about-client-container'>
          {aboutData.map((about, index) => {
            const { id, name, feedback, imgUrl } = about;
            return (
              <aside
                className='about-client-item'
                key={id}
                id={`about-client-item-${index}`}
              >
                <p className='client-item-paragraph'>
                  {showMore ? feedback : feedback.substring(0, 100)}
                  {` `}{' '}
                  {showMore === false && (
                    <span
                      onClick={() => setShowMore(true)}
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                    >
                      ...Show More
                    </span>
                  )}
                </p>
                <div
                  className='img-container'
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <img src={imgUrl} alt='client feedback' />
                </div>
                <p>{name}</p>
              </aside>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default About;
