import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const primaryMainColor = '#ffffff';
// export const primaryDarkColor = "#241D28";
export const secondaryMainColor = '#000000';
// export const secondaryGreyColor = "#B7B5B8";
export const accentMainColor = '#ec7e81';

export const themeStyles = {
  colors: {
    primary: 'red',
  },

  fontSize: {
    primary: '20px',
    secondary: '14px',
  },
};

export const globalStyles = {
  colors: {
    primary: primaryMainColor,
    secondary: secondaryMainColor,
    accent: accentMainColor,
  },
};

export const MuiTheme = createTheme({
  palette: {
    primary: {
      main: primaryMainColor,
      // dark: primaryDarkColor,
    },
    secondary: {
      main: secondaryMainColor,
      // grey: secondaryGreyColor,
    },
    accent: {
      main: accentMainColor,
      // grey: secondaryGreyColor,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 360,
      // sm: 576,
      sm: 600,

      md: 768,
      lg: 957,
      xl: 1024,
      xxl: 1200,
    },
  },
});
