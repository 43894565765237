import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Button from "@mui/material/Button";
import { Container } from '@mui/material';

const Error = () => {
  return (
    <>
      <Navbar />
      <Container
        sx={{
          maxWidth: "1400px",
        }}
        maxWidth={false}
      >
        <Wrapper>
          <div>
            <h1>This page could not be found!</h1>
            <h3>
              We are sorry. But the page you are looking for is not available.
            </h3>
            <Link to={"/"} className="btn" style={{}}>
              <MyButton>back home</MyButton>
            </Link>
          </div>
        </Wrapper>
      </Container>

      <Footer />
    </>
  );
};


const Wrapper = styled.section`
  min-height: 50vh;
  display: grid;
  place-items: center;
  background: var(--clr-primary-10);
  text-align: center;
  h1 {
    font-size: 1.7rem;
    // border: 2px solid red;
    // margin:0;
    // padding: 0;
    @media (min-width: 724px) {
      font-size: 3rem;
    }
  }
  h3 {
    color: var(--clr-grey-3);
    // margin-bottom: 1.5rem;
    // border: 2px solid red;
  }
`;

const MyButton = styled(Button)(({ theme }) => ({
  // marginLeft: "auto",
  // marginRight: "auto",

  // color: theme.palette.primary.secondary,
  // color: "yellow",
  // border: `2px solid`,
  textTransform: "capitalize",
  // color: "",
  backgroundColor: theme.palette.primary.main,
  fontWeight: "900",
  border: "2px solid transparent",
  // border: "2px solid white",
  color: "white",
  // ".hey": {
  //   border: "24px solid yellow",
  // },
  fontSize: "1.2rem",
  padding: "10px 30px",
  marginBottom: 30,

  transition: "all 0.3s ease-in",
  [theme.breakpoints.down(724)]: {
    fontSize: 16,
    padding: "5px 14px",
  },
  "&:hover": {
    // opacity: 0.5,
    boxShadow: "0",
    backgroundColor: "#202bb3",
    color: "white",
    border: "2px solid transparent",
  },
}));
export default Error;
