import BoardRoom from '../svg/Board-Room.svg';
import CoworkingDesk from '../svg/Coworking-Desk.svg';
import DailyUse from '../svg/Daily-Use.svg';
import DedicatedDesk from '../svg/Dedicated-Desk.svg';
import PrivateOffice from '../svg/Private-Office.svg';
import Trainings from '../svg/Trainings.svg';
import VirtualOffice from '../svg/Virtual-Office.svg';

const sliderData = [
  {
    id: 1,
    title: 'Coworking-Desk',
    content: '1-20',
    image: CoworkingDesk,
    link: '/space-solutions-options/co-working-desk',
  },
  {
    id: 2,
    title: 'Dedicated Desk',
    content: '1-20',
    image: DedicatedDesk,
    link: '/space-solutions-options/dedicated-desk',
  },
  {
    id: 3,
    title: 'Private Serviced Offices',
    content: '1-20',
    image: PrivateOffice,
    link: '/space-solutions-options/private-serviced-offices',
  },
  {
    id: 4,
    title: 'Daily Use',
    content: '1-20',
    image: DailyUse,
    link: '/space-solutions-options/daily-use',
  },
  {
    id: 5,
    title: 'Virtual Office Option',
    content: '1-20',
    image: VirtualOffice,
    link: '/space-solutions-options/virtual-office-option',
  },
  {
    id: 6,
    title: 'Board Room',
    content: '1-20',
    image: BoardRoom,
    link: '/space-solutions-options/board-room',
  },
  {
    id: 7,
    title: 'Trainings',
    content: '1-20',
    image: Trainings,
    link: '/space-solutions-options/trainings',
  },
];

export { sliderData };
