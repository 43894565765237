import React, { useEffect } from "react";
import { useLocation } from "react-router";

function ScrollToTop(props) {
  const { pathname } = useLocation();
  //   console.log(pathname);
  // "document.documentElement.scrollTo" is the magic for React Router Dom v6

  //   let location = useLocation();
  //   console.log(location);
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return <>{props.children}</>;
}

export default ScrollToTop;
