import React, {Suspense} from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
// import SpaceSolutions from '../pages/SpaceSolutions';
import SharedLayout from './SharedLayout';

import ScrollToTop from '../helpers/ScrollToTop';
// import AboutUs from '../pages/AboutUs';
// import ContactUs from '../pages/ContactUs';
// import CoworkingOption from '../pages/CoworkingOption';
import CoworkingOptionLayout from '../pages/CoworkingOptionLayout';
import Error from '../pages/Error';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import SpaceSolutionsForm from '../pages/SpaceSolutionsForm';
import VirtualForm from '../pages/VirtualForm';

const AboutUs = React.lazy(() => import('../pages/AboutUs'));
const ContactUs = React.lazy(() => import('../pages/ContactUs'));
const SpaceSolutions = React.lazy(() => import('../pages/SpaceSolutions'));
const CoworkingOption = React.lazy(() => import('../pages/CoworkingOption'));

function RouteComponent() {
  return (
    <div>
      <ScrollToTop>
        <Routes>
          <Route path={'/'} element={<SharedLayout />}>
            <Route index element={<Home />} />
            {/* <Route path="virtual-tour" element={<VirtualTour />} /> */}
            {/* <Route path='virtual-office' element={<VirtualOffice />} /> */}
            <Route
              path='space-solutions'
              element={
                <Suspense fallback={<></>}>
                  <SpaceSolutions />
                </Suspense>
              }
            />
            {/* <Route path="extra-services" element={<ExtraServices />} /> */}
            {/* <Route path="brokers" element={<Brokers />} /> */}
            <Route path='privacy' element={<PrivacyPolicy />} />
            {/* <Route path="why-us" element={<WhyUs />} /> */}
            <Route
              path='about-us'
              element={
                <Suspense fallback={<></>}>
                  <AboutUs />
                </Suspense>
              }
            />
            <Route path='virtual-form' element={<VirtualForm />} />
            <Route
              path='space-solutions-form'
              element={<SpaceSolutionsForm />}
            />
            <Route
              path='contact'
              element={
                <Suspense fallback={<></>}>
                  <ContactUs />
                </Suspense>
              }
            />

            <Route
              path='space-solutions-options'
              element={<CoworkingOptionLayout />}
            >
              <Route
                path=':option'
                element={
                  <Suspense fallback={<></>}>
                    <CoworkingOption />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path={'*'} element={<Error />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default RouteComponent;
