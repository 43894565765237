import { Container } from '@mui/material';
import React from "react";
import Footer from "../components/Footer";

import "./PrivacyPolicy.scss";
function PrivacyPolicy() {
  return (
    <>
      <Container
        sx={{
          maxWidth: "1300px",
        }}
        maxWidth={false}
        style={{
          // border: "2px solid blue",
        }}
      >
        {" "}
        <div className="privacy-policy">
          <h2>COMMUNITYBYDUKKA WEBSITE TERMS OF SERVICE</h2>
          <p>
            <span>Terms:</span> CommunityByDukka (“we” or “us”) owns and
            operates the website at communitybydukka.com (the “Site”), where you
            can find information about our products and services. These Website
            Terms and Conditions (the “Website Terms”) describe the rights and
            obligations of an unregistered website user or visitor (“user” or
            “you”) in connection with your use of the Site. By accessing or
            using the Site in any way, including as an unregistered website
            visitor, you agree to be bound by these Website Terms and our
            Privacy Policy, which is available on the Site. These Website Terms
            apply only to your use of the Site, and the content made available
            on or through the Site, as an unregistered website user or visitor.
            If you use or access any of our physical space, restricted-access
            web-based services (i.e. those requiring a login), the broker or
            referral program or other services we provide, your use of such
            space, services or program is subject to the terms and conditions
            you received or accepted when you signed up for such space, services
            or program. From time to time, we may make modifications, deletions
            or additions to the Site or these Website Terms. Your continued use
            of the Site following the posting of any changes to the Website
            Terms constitutes acceptance of those changes.
          </p>
          <p>
            <span>Content:</span> The text, images, videos, audio clips,
            software, and other content generated, provided, or otherwise made
            accessible on or through the Site (collectively, “Content”) are
            contributed by us and our licensors. The Content and the Site are
            protected by the Nigerian Copyright Act, CAP C28, Laws of the
            Federation of Nigeria, 2004 and all applicable regulations and
            guidelines as may be amended from time to time. We and our licensors
            retain all proprietary rights in the Site and the Content made
            available on or through the Site, and, except as expressly set forth
            in these Website Terms, no rights are granted to any Content. All
            Content is for general informational purposes only. We reserve the
            right, but do not have any obligation to monitor, remove, edit,
            modify or remove any Content, in our sole discretion, at any time
            for any reason or for no reason at all.
          </p>
          <p>
            <span>Disclaimer;</span> Limitation of liability. To the extent
            permitted by law, we and our affiliates, parents, and successors and
            each of our and their employees, assignees, officers, agents and
            directors (collectively, the “CommunityByDukka Parties”) disclaim
            all warranties and terms, express or implied, with respect to the
            Site, Content or services (including third party services) on or
            accessible through the Site, including any warranties or terms of
            merchantability, fitness for a particular purpose, title,
            non-infringement and any implied warranties, or arising from course
            of dealing, course of performance or usage in trade. In no event
            shall the CommunityByDukka Parties be liable under contract, tort,
            strict liability, negligence or any other legal or equitable theory
            with respect to the Site for any special, indirect, incidental,
            punitive, compensatory, or consequential damages of any kind
            whatsoever.
          </p>
          <p>
            <span> Miscellaneous:</span> These Website Terms shall be governed
            by and construed in accordance with the laws of the Federal Republic
            of Nigeria. These Website Terms constitute the entire agreement
            between us regarding the Site and supersedes and merges any prior
            proposals, understandings and contemporaneous communications. If any
            provision of these Website Terms is held to be invalid or
            unenforceable, that provision shall be limited or eliminated to the
            minimum extent necessary so that these Website Terms shall otherwise
            remain in full force and effect and enforceable. In order for any
            waiver of compliance with these Website Terms to be binding, we must
            provide you with written notice of such waiver. The failure of
            either party to enforce its rights under these Website Terms at any
            time for any period will not be construed as a waiver of such
            rights.
          </p>
          <p>
            <span>Contact:</span> If you have any questions, complaints, or
            claims with respect to the Site, you may contact us at CommunityByDukka, 42, Saka Tinubu Street, Victoria Island, Lagos
            at the email address and numbers provided on the Site.
          </p>
        </div>
      </Container>

   
    </>
  );
}

export default PrivacyPolicy;
