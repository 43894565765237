import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import RouteComponent from './Routes/Route';
import { MuiTheme } from './styles/styles';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
// import { globalStyles } from "./styles/styles";
import Logo from './components/assets/communitybydukka@4x.png';
function App() {
  return (
    <ThemeProvider theme={MuiTheme}>
      <RouteComponent />
      <ToastContainer />
      <FloatingWhatsApp
        phoneNumber={'2349157993735'}
        accountName={'CommunityByDukka'}
        avatar={Logo}
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </ThemeProvider>
  );
}

export default App;
