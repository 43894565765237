import React from 'react';
import Button from '@mui/material/Button';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

// export const MyButton = styled(Button)`
//   margin-left: auto;
//   margin-right: auto;
//   color: green;
//   border-color: green;

// `;

// Using MUI Theme
export const MyButton = styled(Button)(({ theme }) => ({
  // marginLeft: "auto",
  // marginRight: "auto",

  // color: theme.palette.primary.secondary,
  // color: "yellow",
  // border: `2px solid`,
  textTransform: 'capitalize',
  // color: "",
  backgroundColor: theme.palette.accent.main,
  fontWeight: '900',
  // border: "2px solid white",
  color: 'white',
  transition: 'all 0.3s ease-in',
  '&:hover': {
    // opacity: 0.5,
    boxShadow: '0',
    backgroundColor: '#e83955',
    color: 'white',
  },
}));
// Using MUI Theme

function ButtonComponent({ children, link, disabled, onClick, border }) {
  return (
    <>
      <Link to={link}>
        <MyButton
          variant='contained'
          endIcon={<DoubleArrowIcon />}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </MyButton>
      </Link>
    </>
  );
}

export default ButtonComponent;
