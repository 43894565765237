export const navLinkList = [
  {
    id: 1,
    path: '/',
    content: 'Home',
  },
  // {
  //   id: 2,
  //   path: "/virtual-office",
  //   content: "Virtual Office",
  // },
  {
    id: 3,
    path: '/space-solutions',
    content: 'Space Solutions',
  },
  // {
  //   id: 4,
  //   path: "/extra-services",
  //   content: "Extra Services",
  // },
];
