import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../contexts/context';
import { Container } from '@mui/material';
import Logo from '../components/assets/community final logo.png';
import { globalStyles } from '../styles/styles';
import './Footer.scss';
function Footer() {
  const styles = { globalStyles };
  const { coworkingOptionSelect, setCoworkingOptionSelect } =
    useGlobalContext();
  return (
    <>
      <div className='footer-container'>
        <Container
          sx={{
            maxWidth: '1400px',
          }}
          maxWidth={false}
        >
          <section className='footer-content'>
            <aside className='content-items-container'>
              <div className='list'>
                <h3>Space Solutions</h3>

                <ul>
                  <li>
                    <Link
                      to={'/space-solutions-options/daily-use'}
                      onClick={() => setCoworkingOptionSelect('Daily Pass')}
                    >
                      Daily Pass
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/space-solutions-options/co-working-desk'}
                      onClick={() =>
                        setCoworkingOptionSelect('Co-working Desk')
                      }
                    >
                      Co-working Desk
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={'/space-solutions-options/private-serviced-offices'}
                      onClick={() =>
                        setCoworkingOptionSelect('Private Serviced Offices')
                      }
                    >
                      Private Serviced Offices
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/space-solutions-options/dedicated-desk'}
                      onClick={() =>
                        setCoworkingOptionSelect('Dedicated Desks')
                      }
                    >
                      Dedicated Desk
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={'/space-solutions-options/board-room'}
                      onClick={() => setCoworkingOptionSelect('Board Room')}
                    >
                      Board room
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/space-solutions-options/trainings'}
                      onClick={() => setCoworkingOptionSelect('Training Hall')}
                    >
                      Training Hall
                    </Link>
                  </li>
                  <li>
                    <Link to={'/space-solutions-options/virtual-office-option'}>
                      Virtual office option
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
            <aside className='content-items-container ' id='location'>
              <div className='list'>
                <h3>Location</h3>
                <p>42, Saka Tinubu Street, Victoria Island, Lagos</p>
              </div>
            </aside>
            <aside className='content-items-container'>
              <div className='list'>
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <Link to={'/about-us'}>About Us</Link>
                  </li>
                  <li>
                    <Link to={'/privacy'}>Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to={'/contact'}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </aside>
          </section>

          <section className='social-links'>
            <div className='main1'>
              <img src={Logo} alt='CommunityByDukka' width='200px' />
              <div>
                General Enquires:
                <address style={{ display: 'inline ' }}>
                  <a
                    href='mailto:digital@dukka.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    id='cbd'
                  >
                    Digital@dukka.com
                  </a>
                </address>
              </div>
            </div>
            {/* <hr className="line" color={"white"} /> */}
            <div className='main2'>
              <p>
                ©CommunityByDukka {new Date().getFullYear()}, All Right Reserved
              </p>
              <aside className='icons'>
                <span>
                  <a
                    href='https://www.instagram.com/dukkacommunity?r=nametag'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <InstagramIcon
                      sx={{
                        color: 'white',
                        marginRight: '10px',
                        '&:hover': {
                          color: '#ec7e81',
                        },
                      }}
                    />
                  </a>
                </span>
                <span>
                  <a
                    href='https://www.facebook.com/profile.php?id=100085607695210'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FacebookIcon
                      sx={{
                        color: 'white',
                        marginRight: '10px',
                        '&:hover': {
                          color: '#ec7e81',
                        },
                      }}
                    />
                  </a>
                </span>
                <span>
                  <a
                    href='https://www.linkedin.com/company/communitybydukka/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <LinkedInIcon
                      sx={{
                        color: 'white',
                        marginRight: '10px',
                        '&:hover': {
                          color: '#ec7e81',
                        },
                      }}
                    />
                  </a>
                </span>
                <span>
                  <a
                    href='https://twitter.com/DukkaCommunity'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <TwitterIcon
                      sx={{
                        color: 'white',
                        marginRight: '10px',
                        '&:hover': {
                          color: '#ec7e81',
                        },
                      }}
                    />
                  </a>
                </span>
                <span>
                  <a
                    href='https://wa.me/2349157993731'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <WhatsAppIcon
                      sx={{
                        color: 'white',
                        marginRight: '10px',
                        '&:hover': {
                          color: '#ec7e81',
                        },
                      }}
                    />
                  </a>
                </span>
              </aside>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
}

export default Footer;
