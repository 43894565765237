import styled from '@emotion/styled';
import CallIcon from '@mui/icons-material/Call';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import mobileLogo from '../components/assets/communitybydukka@4x.png';
import Logo from '../components/assets/community final logo.png';

import './Navbar.scss';
import { navLinkList } from '../helpers/dataContent';
import './Navbar.scss';
import { globalStyles } from '../styles/styles';

const style = {
  globalStyles,
};
export const NavWrapper = styled.nav`
  // border: 2px solid blue;
  display: flex;
  padding: 20px 0;
  // margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  // Little hack to get a globalStyling
  background: ${style.globalStyles.colors.secondary};
  // overflow: hidden;
  // background-color: #333;
  // position: fixed;
  // top: 0;
  width: 100%;
  // position: sticky;
  // top: 0px;
  // z-index: 100;

  h1 {
    // border: 2px solid green;
    color: ${style.globalStyles.colors.primary};
    a {
      color: ${style.globalStyles.colors.primary};
    }
    &:hover {
      // border: 2px solid orange;
    }
  }
  .para {
    // border: 2px solid blue;
    &:hover {
      color: purple;
    }
  }

  @media (min-width: 600px) {
    // border: 2px solid red;
    // padding: 20px 10px;
  }
`;

export const NavListContainer = styled.ul`
  list-style-type: none;
  display: none;

  @media (min-width: 724px) {
    display: inline;
    margin: 0 10px;
    // border: 2px solid red;
  }
`;

export const NavList = styled.li`
  display: none;

  @media (min-width: 600px) and (max-width: 724px) {
    display: none;
  }
  @media (min-width: 724px) {
    .active {
      color: ${style.globalStyles.colors.accent};
    }
    a {
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      color: ${style.globalStyles.colors.primary};
    }
    display: inline;
    margin: 0 10px;
    color: blue;
  }
`;

export function Navbar() {
  const showBar = useMediaQuery('(min-width: 600px) and (max-width: 724px)');
  const showImg = useMediaQuery('(min-width: 601px)');
  const showImgMobile = useMediaQuery('(max-width: 600px)');
  const [showNav, setShowNav] = useState(false);

  const toggleShowNav = () => {
    setShowNav(!showNav);
  };

  return (
    <>
      <div className='navbar'>
        {/* <Container
          sx={(theme) => ({
            maxWidth: "1200px",

            [theme.breakpoints.down(724)]: {
              padding: "0px",
            },
          })}
          maxWidth=
          {false}
        > */}
        <Container
          sx={{
            maxWidth: '1400px',
          }}
          maxWidth={false}
        >
          <NavWrapper>
            <IconButton
              aria-label='menu'
              size='large'
              sx={(theme) => ({
                color: theme.palette.primary.main,
                [theme.breakpoints.up('sm')]: {
                  display: 'none',
                },
              })}
              onClick={() => {
                toggleShowNav();
              }}
            >
              <MenuIcon fontSize='inherit' />
            </IconButton>
            {showBar && (
              <IconButton
                aria-label='menu'
                size='large'
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                })}
                onClick={() => {
                  toggleShowNav();
                }}
              >
                <MenuIcon fontSize='inherit' />
              </IconButton>
            )}
            <div className='community-by-dukka'>
              <NavLink to={'/'}>
                <picture>
                  <source media='min-width: 724px' srcSet={Logo} />
                  {showImgMobile && (
                    <img
                      src={mobileLogo}
                      alt='CommunityByDukka'
                      width={'56px'}
                      style={
                        {
                          // border: "2px solid red",
                        }
                      }
                    />
                  )}
                  {showImg && (
                    <img src={Logo} alt='CommunityByDukka' width={'200px'} />
                  )}
                </picture>
              </NavLink>
            </div>

            <NavListContainer>
              {navLinkList.map((navLink) => {
                const { id, path, content } = navLink;

                return (
                  <NavList key={id}>
                    <NavLink
                      to={path}
                      className={({ isActive }) => {
                        return isActive ? 'link active' : 'link';
                      }}
                      end
                    >
                      {content}
                    </NavLink>
                  </NavList>
                );
              })}
            </NavListContainer>

            <IconButton
              aria-label='call-us'
              size='large'
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  display: 'none',
                },
                color: theme.palette.primary.main,
              })}
            >
              <a
                href='tel:+2342013306129'
                style={{
                  color: style.globalStyles.colors.primary,
                  textDecoration: 'none',
                }}
              >
                <CallIcon fontSize='inherit' />
              </a>
              {/* <CallIcon fontSize="inherit" /> */}
            </IconButton>
            <Typography
              variant='body1'
              sx={(theme) => ({
                border: `2px solid ${theme.palette.primary.main}`,
                padding: '10px 20px',
                fontWeight: 800,
                borderRadius: '20px',
                // color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
                color: theme.palette.secondary.main,
              })}
            >
              <a
                href='tel:+2342013306129'
                style={{ color: style.globalStyles.colors.primary }}
              >
                0201 330 6129
              </a>
            </Typography>
          </NavWrapper>
        </Container>
        {showNav && (
          <Box
            className='list'
            sx={(theme) => ({
              // This works for specifying inbetween mediaquery
              // [theme.breakpoints.between(400, 700)]: {
              //   backgroundColor: "yellow",

              // },
              [theme.breakpoints.up(724)]: {
                display: 'none',
              },
            })}
          >
            <nav className='nav-mobile'>
              {navLinkList.map((navLink) => {
                const { id, path, content } = navLink;

                return (
                  <NavLink
                    key={id}
                    to={path}
                    className={({ isActive }) => {
                      return isActive
                        ? 'link-mobile active-mobile'
                        : 'link-mobile';
                    }}
                    end
                  >
                    {content}
                  </NavLink>
                );
              })}
            </nav>
          </Box>
        )}
        {/* </Container> */}
      </div>
    </>
  );
}

// Test
export default Navbar;
