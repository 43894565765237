import Reviewer3 from '../components/assets/reviewer 3.jpeg';
import Reviewer2 from '../components/assets/reviewer 2.png';
import Reviewer1 from '../components/assets/reviewer 1.jpg';

const aboutData = [
  {
    id: 1,
    name: 'DipFellows',
    imgUrl: Reviewer2,
    feedback:
      'We had our DIP DIVE Sessions at CommunityByDukka and the service was top-notch. The place was properly arranged before the session, and every tool was needed to make the session a success. We would definitely come back.',
  },
  {
    id: 2,
    name: 'Feranmi',
    imgUrl: Reviewer3,
    feedback:
      'Calm surroundings, competent personnel, amazing network, nearby food vendors, excellent position only a few meters from major landmarks and the bus station. Tell me why I would not love CommunityByDukka?',
  },

  {
    id: 3,
    name: 'Afisat',
    imgUrl: Reviewer1,
    feedback:
      "CommunityByDukka offers what I've seen before but in a way that I haven't seen nor experienced, it's quite unique. They prioritize their customers and actually attend to your complains, i love this place!",
  },
];

export { aboutData };
