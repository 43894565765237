import InterestsIcon from '@mui/icons-material/Interests';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarsIcon from '@mui/icons-material/Stars';

const benefitData = [
  {
    id: 1,
    icon: (
      <StarsIcon
        fontSize='large'
        sx={(theme) => ({
          color: theme.palette.primary,
          fontSize: '4rem',
        })}
      />
    ),
    title: 'Customer Service',
    body: 'We absolutely believe that clients are king, and we interact with our clients with this mindset.',
  },
  {
    id: 2,
    icon: (
      <LocationOnIcon
        sx={(theme) => ({
          color: theme.palette.accent,
          fontSize: '4rem',
        })}
      />
    ),
    title: 'Prime Location',
    body: 'We are a community friendly business venue located in the heart of the corporate hub of Lagos, Victoria Island. We are uniquely positioned for easy access, proximity to banks, eateries and restaurants, and hospitals in case of emergency..',
  },
  {
    id: 3,
    icon: (
      <InterestsIcon
        fontSize='large'
        sx={(theme) => ({
          color: theme.palette.primary,
          fontSize: '4rem',
        })}
      />
    ),
    title: 'Variety',
    body: 'Whatever space requirement you want, CommunityByDukka has you covered.',
  },

  // For REFERENCE PURPOSES
  //   {
  //   id: 3,
  //   icon: (
  //     <PeopleIcon
  //       fontSize="large"
  //       sx={(theme) => ({
  //         color: theme.palette.secondary.main,
  //       })}
  //     />
  //   ),
  //   title: "Collaboration",
  //   body: "We provide a platform and springboard for  partnering with other firms and venture partners, which encourages business growth and widens your professional network.",
  // },
  // For REFERENCE PURPOSES
];

export { benefitData };
