import ProductivityImage from '../components/assets/homePage images/productivity.jpg';
import SocialInteractionImage from '../components/assets/homePage images/social interaction.jpg';

const flexCardData = [
  {
    title: 'Connectedness',
    details:
      'We are connecting your today with your desired future with top notch customer experience across our service points.',
    showButton: false,
    imgUrl:
      'https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
  },
  {
    title: 'Productivity',
    details:
      'CommunityByDukka spaces are intentionally designed to aid productivity of her users. From our ergonomic chairs, to the open floor layout, constant power supply, fast internet, and serene atmosphere, our users are primed to get work done faster than usual.',
    showButton: false,
    imgUrl: ProductivityImage,
  },
  {
    title: 'Social interaction',
    details:
      'Collaboration and relationships are two foundational legs that every successful business thrives on. At CommunityByDukka, we provide a platform for high level social interaction that enables productivity and innovation.',
    showButton: false,
    imgUrl: SocialInteractionImage,
  },
  {
    title: 'Flexibility',
    details:
      'Our different service points provide your business with a smugeshboard of options that fits your business desires.',
    showButton: false,
    imgUrl:
      'https://images.unsplash.com/photo-1576849058607-da9d05480027?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80',
  },
];

export { flexCardData}