import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { sliderData } from '../utils/sliderData';
import ButtonComponent from './Button';
import './SliderComponent.scss';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'grey', color: 'blue' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'grey' }}
      onClick={onClick}
    />
  );
}

function SliderComponent() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 724,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='slider-component'>
      {/* <h2> Browse by Coworking Options</h2> */}
      <Slider {...settings}>
        {sliderData.map((slide) => {
          const { id, title, content, link, image } = slide;
          return (
            <div className='card ' key={id}>
              <section className='card-container'>
                <img src={image} alt='slide' />
              </section>
              <section className='card-body'>
                <p>{title}</p>
                <p>1-20</p>
                <ButtonComponent link={link}>See More</ButtonComponent>
              </section>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default SliderComponent;
