import React, { Suspense } from 'react';
import { Audio } from 'react-loader-spinner';
import './OneRoof.scss';
import SliderComponent from './SliderComponenet';

function OneRoof() {
  return (
    <div className='oneroof'>
      <h2
        style={{
          textAlign: 'center',
        }}
      >
        Everything under one-Roof
      </h2>
      <Suspense
        fallback={
          <Audio
            height='100'
            width='100'
            color='#011638'
            ariaLabel='audio-loading'
            wrapperStyle={{}}
            wrapperClass='wrapper-class'
            visible={true}
          />
        }
      >
        <SliderComponent />
      </Suspense>
    </div>
  );
}

export default OneRoof;
