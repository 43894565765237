import React from 'react';
import './Benefits.scss';
import { benefitData } from '../utils/benefitsData';
function Benefits() {
  return (
    <>
      <div className='benefits'>
        <h1>WHAT SETS US APART</h1>
        <div className='benefits-container-wrapper'>
          <section className='benefits-container'>
            {benefitData.map((benefit, index) => {
              const { id, icon, title, body } = benefit;
              return (
                <aside
                  className='benefits-item'
                  key={id}
                  id={`benefits-item-${index}`}
                >
                  <span>{icon}</span>
                  <h3>{title}</h3>
                  <p>{body}</p>
                </aside>
              );
            })}
          </section>
        </div>
      </div>
    </>
  );
}

export default Benefits;
