import React from "react";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
function SharedLayout() {
  return (
    <>
      <Navbar />
      {/* <div style={{height: "70px"}}></div> */}
      <Outlet />
      <Footer />
    </>
  );
}

export default SharedLayout;
