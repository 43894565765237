import React, { useState } from 'react';
import { useContext } from 'react';

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [coworkingOptionSelect, setCoworkingOptionSelect] = useState('');

  return (
    <AppContext.Provider
      value={{ coworkingOptionSelect, setCoworkingOptionSelect }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};
