import { Paper } from "@mui/material";
import React from "react";
import ButtonComponent from "./Button";
import "./FlexCard.scss";

function FlexCard({ reverse, title, details, showButton, imgUrl }) {
  return (
    <>
      <div className={`flex-card-container ${reverse}`}>
        <section className="flex-card-img-container">
          <div className="image-container">
            <Paper elevation={1}>
              <img src={imgUrl} alt="Smiling People" className="dummy" />
            </Paper>
          </div>
        </section>

        <section className="flex-card-details-container">
          <h2>{title}</h2>
          <p>{details}</p>
          {showButton && <ButtonComponent> Learn More </ButtonComponent>}
        </section>
      </div>
    </>
  );
}

export default FlexCard;
