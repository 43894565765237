import React, { useRef, useState } from "react";
import { Oval } from "react-loader-spinner";

import { Link, useNavigate } from "react-router-dom";
import { subDays } from "date-fns";

import "./VirtualForm.scss";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import TextError from "../components/TextError";
import "./VirtualForm.scss";
import { Container } from '@mui/material';
import FormikErrorFocus from "formik-error-focus";
import ButtonComponent from "../components/Button";
import { LinearProgress } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

// import emailjs from '@emailjs/browser';

function VirtualForm() {
  // const form = useRef();
  const [serverState, setServerState] = useState({});
  const [showForm, setShowForm] = useState(true);
  const [submittedForm, setSubmittedForm] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    company: "",
    email: "",
    phone: "",
    startDate: null,
    plan: "Virtual Office Option",
    duration: "Month",
    comments: "",
    sendPromotions: false,
    verifiedRecaptcha: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/^[a-zA-Z]+$/, 'Full Name must contain only letters')
    .required('Required'),
    email: Yup.string()
      .email("Please enter a valid email address!")
      .required("This is a required field"),
    phone: Yup.string()
    .matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Not a valid number')
    .required('This is a required field'),
    startDate: Yup.date().required("This is a required field").nullable(),
    verifiedRecaptcha: Yup.string().required("Recaptcha is a required field"),
    sendPromotions: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions to submit form"
    ),
  });

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };

  const onSubmit = (values, onSubmitProps) => {
  
 
    axios({
      method: "POST",

      url: `https://formspree.io/f/xjvzezel`,

      data: values,
    })
      .then((response) => {
        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
        handleServerResponse(true, "Response was Successfully Submitted");
        setShowForm(false);
        setSubmittedForm(true);

        setTimeout(() => {
          navigate("/");
        }, [1500]);
      })
      .catch((error) => {
        onSubmitProps.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
      });
  };

  return (
    <Container
      sx={{ maxWidth: "960px" }}
      maxWidth={false}
      // style={{ border: "2px solid blue" }}
    >
      <div className="virtual-form">
        <section className="virtual-form-header">
          <h1>Get Started</h1>
          {showForm && (
            <p>
              Complete the form below and a member of our team will get in touch
              to learn more about your workplace needs.
            </p>
          )}
        </section>
        {showForm && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(formik) => {
            
              return (
                <Form>
                  <small>
                    Fields marked with an <span className="error">*</span> are
                    required
                  </small>
                  <div className="form-control">
                    <label htmlFor="name">
                      Full Name <span className="error">*</span>{" "}
                    </label>
                    <Field type="text" name="name" id="name" />
                    <ErrorMessage name="name" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="email">
                      E-mail <span className="error">*</span>{" "}
                    </label>
                    <Field type="email" name="email" id="email" />
                    <ErrorMessage name="email" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="company">Company </label>
                    <Field type="text" name="company" id="compnay" />
                    <ErrorMessage name="company" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="phone">
                      Phone <span className="error">*</span>{" "}
                    </label>
                    <Field type="text" name="phone" id="phone" />
                    <ErrorMessage name="phone" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="startDate">
                      Start Date <span className="error">*</span>{" "}
                    </label>
                    <Field name="startDate">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;

                        return (
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            id="startDate"
                            selected={value}
                            onChange={(val) => setFieldValue("startDate", val)}
                            autoComplete="off"
                            minDate={subDays(new Date(), 0)}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="startDate" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="plan">Plan</label>
                    <Field id="name" as="select" name="plan">
                      <option value={"Virtual Office Option"}>Virtual Office Option</option>
                    </Field>
                    <ErrorMessage name="plan" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="duration">Duration</label>
                    <Field id="duration" as="select" name="duration">
                      <option value={"Month"}>Month</option>
                    </Field>
                    <ErrorMessage name="year" component={TextError} />
                  </div>

                  <div className="form-control">
                    <label htmlFor="comment">
                      Please State Any Additional Requirement{" "}
                    </label>
                    <Field
                      id="comment"
                      as="textarea"
                      name="comment"
                      rows={"4"}
                    ></Field>
                    <ErrorMessage name="comment" component={TextError} />
                  </div>
                  <div
                    className="form-control"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Field
                      type="checkbox"
                      name="sendPromotions"
                      id="sendPromotions"
                      // value="Yes send me promotions"
                    />
                    <label
                      htmlFor="sendPromotions"
                      style={{
                        fontWeight: "normal",
                        fontSize: "1rem",
                        margin: "0px",
                        display: "inline",
                      }}
                    >
                      I consent to{" "}
                      <Link
                        to={"/privacy"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        CommunityByDukka
                      </Link>{" "}
                      storing my data, and contacting me for further
                      communication.
                    </label>
                  </div>
                  <ErrorMessage name="sendPromotions" component={TextError} />

                  <div className="form-control">
                    <ReCAPTCHA
                      sitekey="6Lf69o0iAAAAABxirpMoIUxHkx-StjoopTql9L8m"
                      onChange={(value) => {
                      
                        formik.setFieldValue(
                          "verifiedRecaptcha",
                          "recaptcha was verified"
                        );
                      }}
                    />
                    <ErrorMessage
                      name="verifiedRecaptcha"
                      component={TextError}
                    />
                  </div>
                  {formik.isSubmitting && <LinearProgress />}

                  {/* <button
  type="submit"
  disabled={formik.isSubmitting}
  variant="contained"
>
  Submit
</button> */}
                  <div className="button-container">
                    <ButtonComponent
                      disabled={formik.isSubmitting}
                      onClick={formik.submitForm}
                    >
                      Submit
                    </ButtonComponent>
                  </div>

                  <FormikErrorFocus
                    // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                    offset={-70}
                    align={"top"}
                    focusDelay={200}
                    ease={"linear"}
                    duration={200}
                  />
                </Form>
              );
            }}
          </Formik>
        )}
        {submittedForm && (
          <Oval
            height={80}
            width={80}
            color="#011638"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#011638"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        )}
        {serverState && (
          <p className={!serverState.ok ? "errorMsg" : ""}>{serverState.msg}</p>
        )}
      </div>
    </Container>
  );
}

export default VirtualForm;
